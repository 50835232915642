import React from 'react'
import Layout from '../components/Layout/'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as GS from '../components/GlobalStyles/styles'
import * as S from '../components/EquipeStyles/'

import target from '../img/arrow-target.svg'
import { FUNDAMENTAL, MEDIO } from '../utils/equipe.js'

const Equipe = () => {
  const { check, exato, quadra, biblioteca, sala } = useStaticQuery(
    graphql`
      query {
        quadra: file(relativePath: { eq: "temp-quadra.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        biblioteca: file(relativePath: { eq: "temp-biblioteca.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sala: file(relativePath: { eq: "temp-sala.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        check: file(relativePath: { eq: "colegio-exato-check-mark.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        exato: file(relativePath: { eq: "temp-exato.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  console.log(FUNDAMENTAL)
  return (
    <Layout>
      <S.Header></S.Header>
      <S.Container>
        <S.Title>Ensino Fundamental</S.Title>
        <S.Teachers>
          {FUNDAMENTAL.map((teacher) => (
            <S.Teacher>
              <img src={teacher.picture} alt={teacher.name} />
              <h4>{teacher.name}</h4>
              <p>{teacher.description}</p>
            </S.Teacher>
          ))}
        </S.Teachers>
      </S.Container>
      <S.Divider />
      <S.Container>
        <S.Title>Ensino Médio</S.Title>
        <S.Teachers>
          {MEDIO.map((teacher) => (
            <S.Teacher>
              <img src={teacher.picture} alt={teacher.name} />
              <h4>{teacher.name}</h4>
              <p>{teacher.description}</p>
            </S.Teacher>
          ))}
        </S.Teachers>
      </S.Container>
    </Layout>
  )
}

export default Equipe
