import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Header = styled.div`
  padding: 80px 16px;
  background: ${colors.turbo};
`

export const Container = styled.div`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 16px;
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${colors.bayOfMany};
`

export const Title = styled.h3`
  color: ${colors.bayOfMany};
  text-align: center;
  margin-bottom: 36px;
`

export const Teachers = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

export const Teacher = styled.div`
  width: calc(25% - 12px);
  border: solid 1px ${colors.doveGray};
  padding: 8px;

  @media (max-width: 940px) {
    width: calc(33% - 12px);
  }

  @media (max-width: 768px) {
    width: calc(50% - 16px);
  }

  @media (max-width: 420px) {
    width: 100%;
  }

  img {
    width: 100%;
  }

  h4 {
    text-align: center;
    color: ${colors.bayOfMany};
    margin: 12px 0;
  }

  p {
    text-align: center;
    margin: 0 0 12px;
  }
`
