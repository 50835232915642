import fabricio from '../img/professores/fabricio.jpg'
import gui from '../img/professores/gui.jpg'
import karine from '../img/professores/karine.jpg'
import mirian from '../img/professores/mirian.jpg'
import pauloRicardo from '../img/professores/paulo-ricardo.jpg'
import pollyana from '../img/professores/pollyana.jpg'
import priscila from '../img/professores/priscila.jpg'
import sandro from '../img/professores/sandro.jpg'
import tales from '../img/professores/tales.jpg'
import wanderson from '../img/professores/wanderson.jpg'
import wanessa from '../img/professores/wanessa.jpg'

export const FUNDAMENTAL = [
  {
    name: 'Karine',
    picture: karine,
    description: 'Química'
  },
  {
    name: 'Priscila',
    picture: priscila,
    description: 'Históra'
  },
  {
    name: 'Tales',
    picture: tales,
    description: 'Matemática e Física'
  }
]

export const MEDIO = [
  {
    name: 'Karine',
    picture: karine,
    description: 'Química'
  },
  {
    name: 'Paulo Ricardo',
    picture: pauloRicardo,
    description: 'Literatura'
  },
  {
    name: 'Priscila',
    picture: priscila,
    description: 'Históra'
  },
  {
    name: 'Tales',
    picture: tales,
    description: 'Matemática e Física'
  },
  {
    name: 'Wanderson',
    picture: wanderson,
    description: 'Geografia'
  }
]
